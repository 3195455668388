import { useQuery } from 'react-query';

import { CategoryController } from 'services/apiClient/interfaces/CategoryController';
import { useFiltersState } from 'store/filters';
import { useStaticData } from 'store/staticData';

export function useCategory({ type, geo, slug, limit, initialData, onLoad, lang }) {
  const { isFromBot } = useStaticData();
  const { filterAsQuery } = useFiltersState();

  const { data, isLoading, isSuccess } = useQuery(
    ['category-content', { type, geo, slug, limit, lang, ...filterAsQuery }],
    () => CategoryController.getContentByType({ type, geo, slug, limit, lang, ...filterAsQuery }),
    {
      initialData,
      // This prevents the query to re-run when rendering for bots.
      // All the information is provided by initialEvents coming from getInitialProps
      enabled: !isFromBot,
      onSuccess: d => onLoad?.(d),
      staleTime: Infinity,
    }
  );

  return { data, isLoading, isSuccess };
}
