import { Restaurant } from 'models/Restaurant';

import hosts from '../hosts';
import request from '..';

export class RestaurantController {
  static getInfo = async ({ id, slug, lang }) => {
    const restaurant = await request({
      path: `/v1/restaurants/${id || slug}`,
      baseUrl: hosts.EVENTS_SERVICE,
      headers: { 'Accept-Language': lang },
    });
    return new Restaurant(restaurant);
  };

  static getGallery = ({ id, slug, lang }) => {
    return request({
      path: `/v1/restaurants/${id || slug}/gallery`,
      baseUrl: hosts.EVENTS_SERVICE,
      headers: { 'Accept-Language': lang },
    });
  };

  static getFollowers = ({ id, slug, lang }) => {
    return request({
      path: `/v1/restaurants/${id || slug}/followers`,
      baseUrl: hosts.EVENTS_SERVICE,
      headers: { 'Accept-Language': lang },
    });
  };

  static getReviews = ({ id, slug, lang }) => {
    return request({
      path: `/v1/restaurants/${id || slug}/reviews`,
      baseUrl: hosts.EVENTS_SERVICE,
      headers: { 'Accept-Language': lang },
    });
  };

  static getAll = async ({ lang, ...params }) => {
    const restaurants = await request({
      path: `/v1/restaurants`,
      baseUrl: hosts.EVENTS_SERVICE,
      queryStrings: {
        ...params,
      },
      headers: { 'Accept-Language': lang },
    });

    return restaurants.map(restaurant => new Restaurant(restaurant));
  };

  static getAllByCategory = async ({ lang, slug, ...params }) => {
    const restaurants = await request({
      path: `/v1/restaurants/categories/${slug}`,
      baseUrl: hosts.EVENTS_SERVICE,
      queryStrings: {
        ...params,
      },
      headers: { 'Accept-Language': lang },
    });

    return restaurants.map(restaurant => new Restaurant(restaurant));
  };
}
