import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { ActionBar } from '@xceedsrl/jukebox';

import { useFollow } from 'hooks/useFollow';
import { useAuth } from 'services/authClient';
import { useUser } from 'services/authClient/useUser';
import { useSharedDataActions } from 'store/sharedData';

export function LikeAction({ id, name, type }) {
  const { openAuth } = useSharedDataActions();
  const { isAuthenticated } = useAuth();
  const { user } = useUser();

  const { follow, unfollow } = useFollow(type, {
    id,
    name,
    source: 'card',
  });

  const isLiked = user?.saved?.[type]?.has(id);
  const handleLike = useCallback(() => {
    if (!isAuthenticated) return openAuth();
    return isLiked ? unfollow() : follow();
  }, [isAuthenticated, isLiked]);

  return <ActionBar.Like width="38px" height="40px" onClick={handleLike} isLiked={isLiked} />;
}

LikeAction.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
