import React, { forwardRef, useCallback, useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import * as Types from 'types';

import { ActionBar, composeRef, VenueCard, VenueCardInMap } from '@xceedsrl/jukebox';

import { useTranslation } from 'i18n/';
import { RestaurantController } from 'services/apiClient/interfaces/RestaurantController';
import { useChannelState } from 'store/channel';

import Link from 'components/CustomLink';

import { LikeAction } from './LikeAction';

export const RestaurantWrapper = forwardRef(function RestaurantWrapper(
  { restaurant, likeDisabled, ...props },
  ref
) {
  const { id, name } = restaurant;

  const actionElement = useMemo(() => {
    return (
      <ActionBar gap={1}>
        {likeDisabled ? null : <LikeAction type="restaurants" id={id} name={name} />}
      </ActionBar>
    );
  }, [likeDisabled, id, name]);

  return <VenueCard ref={ref} actionElement={actionElement} {...restaurant} {...props} />;
});

export const RestaurantSmallWrapper = forwardRef(function RestaurantSmallWrapper(
  { restaurant, ...props },
  outerRef
) {
  const { lang, t } = useTranslation();
  const [ref, inView] = useInView({
    rootMargin: '400px',
  });

  const { data: restaurantDetails, refetch } = useQuery(
    ['restaurant', { id: restaurant.id, lang }],
    () => RestaurantController.getInfo({ id: restaurant.id, lang }),
    { enabled: false }
  );

  const setRefs = useCallback(composeRef(ref, outerRef), [ref]);

  useEffect(() => {
    if (inView && !restaurantDetails) refetch();
  }, [inView, restaurantDetails]);

  return (
    <VenueCardInMap
      ref={setRefs}
      name={restaurant.name}
      coverUrl={restaurant.coverUrl}
      type={t('common:restaurant')}
      priceLevel={restaurantDetails?.priceLevel}
      tags={
        restaurantDetails
          ? [...restaurantDetails.facilities, ...restaurantDetails.characteristics]
          : undefined
      }
      {...props}
    />
  );
});

export const LinkedWrapper = forwardRef(function LinkedRestaurantWrapper(
  { card: Card, customChannel, openInNewTab, ...props },
  ref
) {
  const {
    restaurant: { city, slug, name },
  } = props;

  const channel = useChannelState();

  return (
    <Link
      href="/[geo]/restaurant/[...params]"
      as={`/${city.slug}/restaurant/${slug}`}
      channel={customChannel || channel}
      aria-label={name}
      openInNewTab={openInNewTab}
    >
      <Card ref={ref} {...props} />
    </Link>
  );
});

export const LinkedRestaurant = forwardRef((props, ref) => (
  <LinkedWrapper ref={ref} card={RestaurantWrapper} {...props} />
));

export const LinkedRestaurantSmall = forwardRef((props, ref) => (
  <LinkedWrapper ref={ref} card={RestaurantSmallWrapper} {...props} />
));

RestaurantWrapper.propTypes = {
  restaurant: Types.Restaurant.isRequired,
  likeDisabled: PropTypes.bool,
};

RestaurantWrapper.defaultProps = {
  likeDisabled: false,
};

RestaurantSmallWrapper.propTypes = {
  restaurant: Types.Restaurant.isRequired,
};

LinkedWrapper.propTypes = {
  card: PropTypes.oneOf([RestaurantWrapper, RestaurantSmallWrapper]).isRequired,
  customChannel: PropTypes.string,
  openInNewTab: PropTypes.bool,
  ...RestaurantWrapper.propTypes,
};

LinkedWrapper.defaultProps = {
  customChannel: null,
  openInNewTab: false,
};

LinkedRestaurant.propTypes = RestaurantWrapper.propTypes;

LinkedRestaurantSmall.propTypes = RestaurantSmallWrapper.propTypes;
