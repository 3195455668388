/* eslint-disable react/prop-types */
import React from 'react';
import ContentLoader from 'react-content-loader';

import { Button, ChevronRight, Flex, Icon, Text } from '@xceedsrl/jukebox';

export const SkeletonHeader = props => (
  <ContentLoader
    viewBox="0 0 380 54"
    backgroundColor="#f4f4f4"
    foregroundColor="#ffffff"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <rect x="6" y="6" rx="6" ry="6" width="300" height="24" />
    <rect x="6" y="36" rx="3" ry="3" width="200" height="12" />
  </ContentLoader>
);

export const SeeAllButton = React.memo(({ text }) => (
  <Flex flexDirection="column" alignItems="center" justifyContent="center" height="100%">
    <Button variant="round" size="medium" bg="transparent" boxShadow={0}>
      <Icon fontSize="18px" color="nightgraph">
        <ChevronRight />
      </Icon>
    </Button>
    <Text fontWeight={900} color="nightgraph">
      {text}
    </Text>
  </Flex>
));
